import Typography from "@mui/material/Typography";

interface FooterTitleProps {
    text: string;
}
const FooterTitle: React.FC<FooterTitleProps> = ({text}) => {
    return (
        <>
            <Typography variant="h6" color="white">{text}</Typography>
            <hr style={{ borderColor: '#4A8FAF', width: '30%', float: 'left', margin: 0, borderWidth: '3px' }} /><br></br>
        </>
    )
}

export default FooterTitle;