import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import ChatStep, { StepObj } from "../../../widgets/ChatStep";

const theme = createTheme();

const ChatSolutionSection = () => {

    const goToContact = () => {
        const el = document.getElementById('ContactDiv');
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('el not found');
        }
    }

    return (
        <Box style={{
            backgroundColor: '#F5F9FB',
            backgroundSize: "cover",
            // color: 'white',
            // height: "100vh",
        }}>
            <Container maxWidth="lg" sx={{ p: 4 }}>
                <Grid container spacing={1} sx={{ mb: 4 }}>
                    <Grid item xs={6} sx={{ my: 'auto' }}>
                        <Container>
                            <Typography fontWeight={600} variant="h4" color="initial" sx={{ mb: 2 }}>Quick and
                                <span style={{ color: theme.palette.primary.main }}> hassel-free </span> <br />
                                implementation</Typography>

                            <Typography variant="body1" color="initial">Move forward with technology and implement a solution that does exactly what you need.</Typography>
                            <Button variant="contained" sx={{ backgroundColor: 'primary.dark', my: 3, color: 'white' }} onClick={() => goToContact()}>
                                Talk to us
                            </Button>
                        </Container>
                    </Grid>
                    <Grid item xs={6}>
                        <Container sx={{ p: 3, width: '80%' }}>
                            <img src="/img/phone-chats.png" width={'100%'} />
                        </Container>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
}

export default ChatSolutionSection;