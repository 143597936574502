import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

type props = {
    title: string,
    background: string,
    subtitle: string,
    category?: string,
    testimonial?: {
        text: string,
        name: string,
    },
    btn?: {
        text: string,
        link: string,
        background: string,
        color: string,
    },
    coolStatement?: string,
}

const CoverSection = ({ title, background, subtitle, testimonial, btn, category, coolStatement }: props) => {
    return (
        <div>
            <Box style={{
                backgroundImage: `url("/img/${background}")`,
                backgroundSize: "cover",
                color: 'white',
                // height: "100vh",
            }}>
                <Container max-width="lg" sx={{ p: 4, pt: 12, pb: 12 }}>
                    {category && (
                        <Typography variant="body1" fontWeight={500} lineHeight={1.4}>{category}</Typography>
                    )}
                    <Typography variant="h3" fontWeight={600} lineHeight="1.4" paddingBottom={0}>
                        {title}
                    </Typography>
                    <Typography variant="h5" fontWeight={500} lineHeight="1.4" component="span" sx={{ ml: 0 }} color='primary.contrastText'>
                        {subtitle}
                    </Typography>
                    {testimonial && (<Typography variant="h6" paddingTop={2}>
                        {`${testimonial?.text}  - ${testimonial?.name}`}
                    </Typography>)}

                    {btn && (<Button variant="contained" sx={{ backgroundColor: `${btn.background}`, my: 3, color: `${btn.color}`, display: 'block' }} onClick={() => { }}>
                        {btn.text}
                    </Button>)}
                </Container>
            </Box>
            {coolStatement && (<Box style={{
                backgroundColor: 'black',
                backgroundSize: "cover",
                color: 'white',
            }}>
                <Container max-width="lg" sx={{ py: 4, textAlign: 'center' }}>
                    <Typography variant="h4" fontWeight={600} lineHeight="1.4" paddingBottom={0}>
                        {coolStatement}
                    </Typography>
                </Container>
            </Box>)}

        </div>
    );
};

export default CoverSection