import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ContactForm from "../../widgets/ContactForm";

const ContactUs = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <Container max-width="lg" sx={{ p: 4, pt: 0, py: 8 }} id={'ContactDiv'}>
            <Grid container>
                <Grid item md={6} className="hide-mobile">
                    <Container>
                        <Typography variant="body1" color="primary" fontWeight="600">
                            TALK TO US
                        </Typography>

                    </Container>
                    <Container>
                        <Typography variant="h5" fontWeight={600} marginBottom={2}>Let's Build Together</Typography>
                    </Container>
                    <Container>
                        <Typography variant="body2" marginBottom={7} maxWidth={'800px'}>Ready to get started or have a question? Get in touch with us now.</Typography>
                    </Container>


                    <img src="/img/WorldMap.png" alt="" width={'130%'} style={{ zIndex: 0 }} />
                </Grid>
                <Grid item md={6} sm={12}>
                    {
                        isMobile && (
                            <>
                                <Container>
                                    <Typography variant="body1" color="primary" fontWeight="600" sx={{ pb: 2 }}>
                                        TALK TO US
                                    </Typography>
                                    <Typography variant="body2" marginBottom={3} maxWidth={'800px'}>Ready to get started or have a question? Get in touch with us now.</Typography>
                                </Container>
                            </>
                        )
                    }

                    <ContactForm />
                </Grid>
            </Grid>
        </Container>
    );
}

export default ContactUs;