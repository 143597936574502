import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import ChatStep, { StepObj } from "../../../widgets/ChatStep";

const BuildYourBot = () => {

    const steps: StepObj[] = [
        {
            count: 1,
            img: '/img/chat-stuff.png',
            title: 'Upload your knowledge',
            desc: 'Give the AI solution all the necessary information so it can accurately assist your customers with their enquiries'
        },
        {
            count: 2,
            img: '/img/chat-stuff.png',
            title: 'Receive A Testing Bot',
            desc: 'After approx. 2-4 weeks, our team will complete the development and share with you a link to test your chatbot'
        },
        {
            count: 3,
            img: '/img/chat-stuff.png',
            title: 'Go Live',
            desc: 'Share your website access and our team will implement the chat solution on your tech platform'
        },
    ]

    return (
        <Container maxWidth="lg" sx={{ p: 4 }}>
            <Container sx={{ textAlign: 'center' }}>
                <Typography sx={{ mb: 1 }} variant="h5" color="initial" fontWeight={600}>Build your own chat solution with Conversational AI</Typography>
                <Typography sx={{ px: 10, mb: 5 }} variant="body1" color="initial">We offer AI Chat Solutions consultations for your business needs. Talk to one consultants to understand how your business can benefit from the future of AI.</Typography>
            </Container>

            <Grid container spacing={1}>
                {steps.map((x) => (
                    <Grid item xs={4} key={`x-${x.count}`}>
                        <ChatStep {...x} />
                    </Grid>
                ))}
            </Grid>
        </Container >
    );
}

export default BuildYourBot;