import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Grid, Divider } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


const AboutUs = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <Box style={{
            backgroundColor: 'white',
            backgroundSize: "cover",
            color: 'white',
            // height: "100vh",
        }}>
            <Container max-width="lg" sx={{ p: 4, py: 8 }}>
                <Typography variant="body1" color="primary" fontWeight="600">
                    ABOUT US
                </Typography>

                <Typography variant="h5" color="#404145" fontWeight="600">
                    WE WILL BE YOUR TECH PARTNER <br />TO MOVE YOUR BUSINESS <br />ONLINE
                </Typography>

                <Grid container sx={{ mt: 5 }} >
                    <Grid item md={6} sx={{ position: 'relative', display: 'block', '@media(max-width: 768px)': { display: 'none' } }} >
                        <Box sx={{ position: 'relative' }}>
                            <img src="/img/AboutUs1.png" alt="" />
                        </Box>
                        <Box sx={{ position: 'absolute', top: 30, left: 30 }}>
                            <img src="/img/AboutUs2.png" alt="" />
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} padding={isMobile ? 0 : 5}>
                        <Typography variant="body2" color="black" align="justify">
                            Excelfixer is a web/app development service providers with a competitive edge Mobile App Development, Web Applications & AI-Solutions in Malaysia.Founded in 2020, we have successfully become the tech partner to over 20 firms in Malaysia developing various Mobile & Web Apps.
                        </Typography>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowCircleRightIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText secondary="Our goal is to empower our partner businesses to build responsive Mobile & Web Apps with AI Capabilities."></ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ArrowCircleRightIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText secondary="Our tech experts in Malaysia will work with you closely to develop the Best Apps for your Business."></ListItemText>
                        </ListItem>
                    </Grid>
                </Grid>
                <Box justifyContent="center" display="flex" zIndex={1} sx={{ position: 'relative', left: isMobile ? 0 : 40 }}>
                    <Box display="flex" sx={{ backgroundColor: 'primary.main', width: '40vw', minWidth: '280px' }}>
                        <Grid container color={'white'} spacing={0} padding={'20px'}>
                            <Grid item xs={5} justifyContent={'center'} alignItems="center" display={'flex'}>
                                <Grid item alignItems={'center'} justifyContent="center">
                                    <Typography align={"center"} variant="h4">20+</Typography>
                                    <Typography align={"center"} variant="body1">Projects Delivered</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} justifyContent="center" display="flex">
                                <Divider sx={{ width: '0.5px', backgroundColor: 'white' }} orientation='vertical' />
                            </Grid>
                            <Grid item xs={5} justifyContent={'center'} alignItems="center" display={'flex'}>
                                <Grid item>
                                    <Typography align={"center"} variant="h4">20+</Typography>
                                    <Typography align={"center"} variant="body1">Happy Clients</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
};

export default AboutUs;