import ResponsiveAppBar from "./Header";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button';
import AboutUs from './sections/AboutUs';
import Products from "./sections/Products";
import OurWork from "./sections/OurWork";
import Team from "./sections/Team";
import ContactUs from "./sections/ContactUs";
import Footer from "../widgets/Footer";
import Seo from "../widgets/Seo";
import ClutchTestimonial from "../widgets/ClutchTestimonial";
import { HiddenH1, HiddenH2 } from "../widgets/HiddenH1";

const Layout = () => {

    const goToContact = () => {
        const el = document.getElementById('ContactDiv');
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('el not found');
        }
    }
    return (
        <div>
            <ResponsiveAppBar />
            <Seo title="Mobile App Development in Malaysia | Excelfixer" desc="Leading mobile & web app development agency in Malaysia. Our tech developers build the best iOS and Android mobile apps using hybrid technologies such as Flutter & React for your business & personal needs."></Seo>
            <Box style={{
                backgroundImage: `url("/img/home_cover_img2.png")`,
                backgroundSize: "cover",
                color: 'white',
                // height: "100vh",
            }}>
                <Container max-width="lg" sx={{ p: 4, pt: 12, pb: 12 }}>
                    <HiddenH1 text="Southeast Asia & Malaysia's Top Mobile Application in IOS and Android, Website & Web Development Software Company." />
                    <HiddenH2 text="We are the best mobile app and web development company in Malaysia, Kuala Lumpur, Petaling Jaya, Singapore, Australia and Southeast Asia. We have expertise in building mobile application for e-commerce. Our mobile app development team build mobile apps in both IOS and Android. We use javascript and node.js to build web platforms for automobile, agriculture, database solutions." />
                    <Typography variant="h3" fontWeight={600} lineHeight="1.4" paddingBottom={3}>
                        We make your digital<br></br> ideas a
                        <Typography variant="inherit" component="span" sx={{ ml: 2 }} color='primary.contrastText'>
                            reality
                        </Typography>
                    </Typography>
                    <Typography variant="h6">
                        "After EXCELFIXER rebuilt our App, our sales conversion rate<br></br> doubled"  -En. Fakhrul <span>reality</span>
                    </Typography>
                    <Button variant="contained" sx={{ backgroundColor: '#52E9FF', my: 3, color: 'white' }} onClick={() => goToContact()}>
                        Talk to us
                    </Button>
                </Container>
            </Box>
            <AboutUs />
            <Products />
            <OurWork />
            <Team />
            <ClutchTestimonial />
            <ContactUs />
            <Footer />
        </div >
    )

};

export default Layout;