import CoverSection from "../../widgets/CoverSection";
import Seo from "../../widgets/Seo";
import BuildYourBot from "../sections/AiChat/BuildYourBot";
import ChatSolution from "../sections/AiChat/ChatSolution";
import PainPoints from "../sections/AiChat/PainPoints";
import ContactUs from "../sections/ContactUs";

const AiChat = () => {

    const aiBtn = {
        text: "Try it out!",
        link: "a",
        background: 'white',
        color: 'black',
    }


    return (
        <div>
            <Seo title="AI Chat | Malaysia" desc="Develop AI Chat solutions using Chat GPT or similar NLP models in Malay or any other languages. Ready chat solutions for Malaysian companies and government sectors."></Seo>
            {/* <CoverSection title="Welcome to the future of customer engagement. Meet our AI Chatbot!" subtitle="" background="chat-cover.png" btn={aiBtn} category="Ai Chatbot" /> */}
            <CoverSection title="Welcome to the future of customer engagement. Meet our AI Chatbot!" subtitle="" background="chat-cover.png" category="Ai Chatbot" />
            <PainPoints />
            <BuildYourBot />
            <ChatSolution />
            <ContactUs />
        </div>
    );
};

export default AiChat;