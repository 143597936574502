import Footer from "../widgets/Footer";
import Seo from "../widgets/Seo";
import ResponsiveAppBar from "./Header";
import ContactUs from "./sections/ContactUs";

const ContactPage = () => {
    return (
        <div>
            {/* <h1>a</h1> */}
            <ResponsiveAppBar />
            <Seo title="Talk to Excelfixer" desc="Get in touch with Excelfixer to get a quote for your mobile app or web development"></Seo>
            <ContactUs />
            <Footer />
        </div>
    );
}

export default ContactPage;