import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import zIndex from "@mui/material/styles/zIndex";

export interface workObj {
    title: string,
    type: string,
    desc: string,
    img: string,
    left: boolean,
}

interface OurWorkObjectProps {
    workObj: workObj;
}

const OurWorkObject: React.FC<OurWorkObjectProps> = (workObj) => {

    const isMobile = window.innerWidth <= 768;

    return (
        // if mobile , no need left right and dont show the dots thingy

        <Grid container marginY={5}>
            {workObj.workObj.left && !isMobile ? (
                <>
                    <Grid item md={6} sm={12}>
                        <Box sx={{ position: 'relative' }} padding={5}>
                            <img src={workObj.workObj.img} alt="" style={{ width: '100%', height: 'auto' }} />
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            zIndex: '1'
                        }}>                            
                            <div style={{ zIndex: '1' }}>
                            <Typography variant="h5">{workObj.workObj.title}</Typography>
                            <Typography variant="body1" color="neutral.light" marginBottom={2}>{workObj.workObj.type}</Typography>
                            <Typography variant="body1" marginBottom={2}>{workObj.workObj.desc}</Typography>
                            {/* <Button variant="contained" sx={{ backgroundColor: 'primary.dark', color: 'white', width: '120px', borderRadius: '10px', padding: '10px', fontWeight: '600', textTransform: 'none' }}>
                                Know more
                            </Button> */}
                            </div>
                        </Box>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item md={6} sm={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            zIndex: '1'
                        }}>
                            { !isMobile && (<img src="/img/leftDots.png" alt="" style={{ position: 'absolute', height: '100px', left: '-30px', zIndex: '0' }} />)}
                            <div style={{ zIndex: '1' }}>
                                <Typography variant="h5">{workObj.workObj.title}</Typography>
                                <Typography variant="body1" color="neutral.light" marginBottom={2}>{workObj.workObj.type}</Typography>
                                <Typography variant="body1" marginBottom={2}>{workObj.workObj.desc}</Typography>
                                {/* <Button variant="contained" sx={{ backgroundColor: 'primary.dark', color: 'white', width: '120px', borderRadius: '10px', padding: '10px', fontWeight: '600', textTransform: 'none' }}>
                                    Know more
                                </Button> */}
                            </div>

                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Box sx={{ position: 'relative' }} padding={5}>
                            <img src={workObj.workObj.img} alt="" style={{ width: '100%', height: 'auto' }} />
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default OurWorkObject;