import { Helmet } from "react-helmet-async";

type props = {
    title: string,
    desc: string,
    // type: string,
}

const Seo = ({ title, desc }: props) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={desc} />
            <meta property="og:type" content="type" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
        </Helmet>
    );
};

export default Seo;