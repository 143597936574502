import { Box, Container, Grid, Typography } from '@mui/material';
import '../styles/staff.css';


export interface Staff {
    name: string;
    img: string;
    position: string;
}

interface StaffBoxProps {
    staff: Staff;
}


const StaffBox: React.FC<StaffBoxProps> = ({staff}) => {
    return (
        <Grid item md={4} sm={6}>
            <Box sx={{ position: 'relative', }}>
                <img src={staff.img} alt="" width={'100%'} style={{ zIndex: '0', boxShadow: '8px 10px 20px rgba(0, 0, 0, .25)', borderRadius: '2px' }} />

                <Box
                className='staff-box'
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        // // backgroundColor: 'white',
                        // color: 'black',
                        zIndex: '1',
                        width: '100%',
                        // paddingY: '20px',
                        // transition: 'transform 0.3s',
                        // '&:hover': {      
                            // transform: 'height(1.05)',                      
                            // // height: '90%',
                            // // background: 'linear-gradient(180deg, #4A8FAF 0%, #4A8FAF 0.01%, rgba(74, 143, 175, 0.16) 100%)',
                            // color: 'white',
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                            // '& .hoveredTypography': {
                            //     color: 'white',
                            //   },
                        // },

                    }}
                >
                    <Box sx={{ display: 'block' }}>
                        <Typography textAlign={'center'} variant="h6" fontWeight={600}>{staff.name}</Typography>
                        <Typography className="hoveredTypography" textAlign={'center'} variant="body1" color="primary">{staff.position}</Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>








    )
}

export default StaffBox;