import { Box, Container, Typography, Grid } from "@mui/material";
import ProductBox, { Product } from "../../widgets/product_box";
// interface Product {
//     src: string;
//     title: string;
//     desc: string;
// }
const Products = () => {
    const productsData: Product[] = [
        {
            src: '/img/icons/WebAppsIcon.png',
            title: 'Web Portals',
            desc: 'Multi-functional web platforms, customized for your business needs'
        },
        {
            src: '/img/icons/MobileAppIcon.png',
            title: 'Mobile Apps',
            desc: 'Build apps at an affordable rate and in rapidly. (Android & IOS)'
        },
        {
            src: '/img/icons/eCommerceIcon.png',
            title: 'E-Commerce',
            desc: 'Both out of the box as well as custom-made to boost your online sales'
        },
        {
            src: '/img/icons/APIIcons.png',
            title: 'API Development',
            desc: 'Develop your own REST API together with clear documentation to allow third-party integrations'
        },
        {
            src: '/img/icons/MaintenanceIcon.png',
            title: 'Google Ads & SEO',
            desc: 'Need help running value-for-money ads? Let our experts do the work'
        },
        {
            src: '/img/icons/WebDesignIcon.png',
            title: 'Web Design',
            desc: 'Re-brand your website with the on-the-edge design with the latest UX-UI to improve your brand presence'
        }
    ];

    return (
        <Box sx={{ backgroundSize: "cover", color: 'white', backgroundImage: `url("/img/ProductsSQBackground.png")` }}>
            <Container max-width="lg" sx={{ p: 4, py: 8 }}>
                <Typography variant="h4" paddingBottom={2}>What we do</Typography>
                <Typography variant="body1">Our range of services are all built on our core competency of database architecture and REST API framework with expertise in Javascript development.</Typography>
                <Grid container spacing={5} marginY={4}>
                    {productsData.map((item, index) => (
                        <Grid item md={4} sm={1} key={`p-${index}`}>
                            <ProductBox product={item} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Products;