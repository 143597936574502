import { BrowserRouter, Outlet, Route, Router, Routes } from "react-router-dom";
import Footer from "../../widgets/Footer";
import ResponsiveAppBar from "../Header";
import ProductsMain from "../products/ProductsMain";
import WebDesign from "../products/WebDesign";

const HeaderFooter = () => {
    return (
        <div>
            <ResponsiveAppBar></ResponsiveAppBar>
            <Outlet />
            <Footer />
        </div>
    );
};


export default HeaderFooter;