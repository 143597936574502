import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
import OurWorkObject, { workObj } from '../../widgets/OurWorkObject';
import pastWorkData from '../../data/pastwork.json';

const ourWorkData: workObj[] = pastWorkData

const OurWork = () => {
    return (
        <div>
            <Box style={{
                backgroundColor: 'white',
                backgroundSize: "cover",
            }}>
                <Container max-width="lg" sx={{ p: 4, pt: 8, pb: 0 }}>
                    <Container sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Typography variant="body1" color="primary" fontWeight="600">
                            OUR WORK
                        </Typography>

                    </Container>
                    <Container sx={{ justifyContent: 'center', display: 'flex' }}>

                        <Typography variant="h5" fontWeight={600} textAlign={'center'}>Our clients and their products</Typography>
                    </Container>
                    {ourWorkData.map((item, index) => (
                        <OurWorkObject key={`ow-${index}`} workObj={item} />
                    ))}

                </Container>
            </Box>
        </div>
    );
}
export default OurWork;