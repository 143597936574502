import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import "@fontsource/poppins";



let theme = createTheme({
  palette: {
    primary: {
      main: '#4A8FAF',
      dark: '#1D233A',
      light: '#242D50',
      contrastText: '#52E9FF'
    },
    secondary: {
      main: '#f44336',
    },
    text: {
      primary: "#4A8FAF",
    },
    neutral: {
      main: '#404145',
      light: '#828282',
      contrastText: '#BDBDBD'
    }
  },
  typography: {
    fontFamily:
      'Poppins'
  }
});

theme = responsiveFontSizes(theme);

declare module '@mui/material/styles' {

  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

export default theme;