import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
import OurWorkObject, { workObj } from '../widgets/OurWorkObject';
import ResponsiveAppBar from './Header';
import Seo from '../widgets/Seo';
import CoverSection from '../widgets/CoverSection';
import customersData from '../data/customer.json';
import PastWorkRow from '../widgets/PastWorkRow';
import { Grid } from '@mui/material';
import "../styles/work.css";
import { HiddenH1, HiddenH2 } from '../widgets/HiddenH1';



type rowData = {
    title: string,
    desc: string,
}



// const dataToLoop: rowData[] = [customersData];
const stuff = [1, 2, 3];

const OurWork = () => {

    const customerEntry = () => {
        const arr: any[] = []
        Object.entries(customersData).forEach(([key, value]) => {
            arr.push(value)
        })
        return arr
    }

    const testi = {
        text: 'They did a great job in showcasing our brand image via a web profile that was slick and modern.',
        name: 'Mr. Mouhanad'
    }

    return (
        <div>

            <Seo title="Top Tech Partner for Startups in Asia" desc="Build your startup with the best tech partner in Asia. Our top tech team will be your partner through your startup journey to build your app in ASIA. We will ensure your app scales cost-effectively as you grow your startup presence in Asia."></Seo>
            <HiddenH1 text="We are the best tech partner for you to start your new startup in Asia" />
            <HiddenH2 text="Our tech experts are based in Asia and can build your app that scales. Your company will need the best tech partner for your startup to be successful and we are leading the Asia market to assist your startup." />
            <CoverSection title='Our Work' subtitle="Our journey with past clients" background="work-background.png" testimonial={testi} coolStatement='INSPIRE.NOW' />

            <Box style={{
                backgroundColor: 'white',
                backgroundSize: "cover",
            }}>
                {/* <Container max-width="lg" sx={{ p: 4, py: 4 }}>
                    <Typography variant="body1" color="initial" sx={{ textAlign: 'center' }}>
                        Some of our work that has helped our client heighten their market positioning in the digital landscape
                    </Typography>
                </Container> */}
            </Box >

            {
                Object.entries(customersData).map(([key, value]) => {
                    return <PastWorkRow key={`ow-${key}`} title={value.title} category={value.category} company={value.name} image={`/img/${key}/CoverImage.png`} hoverColor={value.row.hover.background} textColor={value.row.hover.text} link={key} companyKey={value.companyKey} hoverImageLeftPosition={value.row.hover.left} />
                })
            }
        </div >
    );
}
export default OurWork;