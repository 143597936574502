import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import StaffBox, { Staff } from "../../widgets/StaffBox";
import Grid from '@mui/material/Grid'

const teamData: Staff[] = [
    { name: 'Aashwiin Roghan', img: '/img/team/ash.png', position: 'Founder & CEO' },
    { name: 'Abdul', img: '/img/team/abud.png', position: 'Frontend Developer' },
    { name: 'Aisyah', img: '/img/team/aisyah.png', position: 'Backend Developer' },
    { name: 'Yogapriya', img: '/img/team/yoga.png', position: 'UX-Designer' },
    { name: 'Asyraf', img: '/img/team/asyraf.png', position: 'Backend Lead' },
    { name: 'Yahya', img: '/img/team/yahya.png', position: 'Lead Frontend Developer' },
    { name: 'Adrian', img: '/img/team/adrian.png', position: 'Frontend Developer' },
    { name: 'Maher', img: '/img/team/maher.png', position: 'Junior Developer' },
];

const Team = () => {
    return (
        <Box style={{
            backgroundColor: 'white',
            backgroundSize: "cover",
        }}>
            <Container max-width="lg" sx={{ p: 4, pt: 0, pb: 8 }}>
                <Container sx={{ justifyContent: 'center', display: 'flex' }}>
                    <Typography variant="body1" color="primary" fontWeight="600">
                        OUR TEAM
                    </Typography>

                </Container>
                <Container sx={{ justifyContent: 'center', display: 'flex', }}>
                    <Typography variant="h5" fontWeight={600} textAlign={'center'} marginBottom={2}>Our Dedicated Team Members</Typography>
                </Container>
                <Container sx={{ justifyContent: 'center', display: 'flex' }}>
                    <Typography variant="body2" marginBottom={7} textAlign={'center'} maxWidth={'800px'}>We pride ourselves in building a team of developers who are not only well-versed in coding but also have a strong business acumen to ensure our partners’ goals are met.</Typography>
                </Container>
                <Grid container rowSpacing={7} columnSpacing={10}>
                    {teamData.map((item, index) => (
                        <StaffBox key={`tm-${index}`} staff={item} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Team;