import { Box, Container } from "@mui/material";
import { useEffect } from "react";

const ClutchTestimonial = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.clutch.co/static/js/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Box style={{
            backgroundColor: '#FAFAFA',
            backgroundSize: "cover",
        }}>
            <Container max-width="lg" sx={{ p: 4, pt: 4, pb: 0 }}>

                <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="12" data-height="375" data-nofollow="true" data-expandifr="true" data-scale="100" data-primary-color="#00bcd4" data-secondary-color="#00bcd4" data-reviews="2124027,1903281,1831217,1810304,1789926,1785781,1782024" data-clutchcompany-id="1779305"></div>
            </Container>
        </Box>
    );
}

export default ClutchTestimonial;