import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './widgets/ScrollToTop';

import 'babel-polyfill';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const tagManagerArgs = {
  gtmId: 'GTM-K974WRM',
}

TagManager.initialize(tagManagerArgs)

const rootStyles = {
  //  Update your root styles here    
}

const helmetContext = {};



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <div style={rootStyles}>
        <App />
      </div>
    </HelmetProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

