import { Box, Container, Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import CameraIcon from '@mui/icons-material/Camera';

const PainPointsSection = () => {
    return (
        <Container maxWidth="lg" sx={{ p: 4, py: 4 }}>
            {/* <Container sx={{ textAlign: 'center', p: 2 }}>
                <Typography variant="subtitle1" fontWeight={700} color="initial">Are you constantly facing the need to answer the same questions?</Typography>
            </Container> */}
            <Box sx={{ background: '#F5F9FB', p: 4 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sx={{ my: 'auto' }}>
                        <Typography variant="body1" fontWeight={600} color="initial" sx={{ mb: 1 }}>Any of the points below resonants with you?</Typography>
                        <List>
                            <ListItem>
                                <CameraIcon sx={{ pr: 1 }} />
                                <ListItemText secondary="Customers keep asking the same question" />
                            </ListItem>
                            <ListItem>
                                <CameraIcon sx={{ pr: 1 }} />
                                <ListItemText secondary="Customers don’t like the current chatbot system that feels very robotic" />
                            </ListItem>
                            <ListItem>
                                <CameraIcon sx={{ pr: 1 }} />
                                <ListItemText secondary="Enquiries require us to go through alot of data / refer to documents to get the right answers" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        <Container sx={{ textAlign: 'center', width: '80%', }}>
                            <img src="/img/lady_with_phone.png" alt="" width={"100%"} />
                        </Container>
                    </Grid>
                </Grid>
            </Box>
        </Container >
    );
}

export default PainPointsSection;