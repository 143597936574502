import { Box, Typography } from "@mui/material";


export interface Product {
    src: string;
    title: string;
    desc: string;
}

interface ProductBoxProps {
    product: Product;
}

const ProductBox: React.FC<ProductBoxProps> = ({product}) => {
    // const {src, name, desc} = product;
    return (
        <Box sx={{
            color: 'white', backgroundColor: 'primary.light', transition: 'transform 0.3s',
            '&:hover': {
                transform: 'scale(1.05)',
                backgroundColor: '#2B3458'
            },
        }} padding={5}>
            <div style={{ "position": "relative", 'marginBottom': '15px' }}>
                <Box sx={{ position: 'relative' }}>
                    <img src={product.src} alt="" width={90} />
                </Box>
            </div>
            <Typography variant="h5" marginBottom={2}>{product.title}</Typography>
            <Typography variant="body1" sx={{ WebkitLineClamp: 3, overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', height: '4.8em', lineHeight: '1.6em' }}>{product.desc}</Typography>
        </Box>
    );
}
export default ProductBox;