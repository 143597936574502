import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";


const ContactForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        company: '',
        phone: '',
        email: '',
        comment: '',
    });

    const [errors, setErrors] = useState({ name: false, company: false, phone: false, email: false, comment: false });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { name, company, phone, email, comment } = formData;

        const newErrors = {
            name: name === '',
            company: company === '',
            phone: phone === '',
            email: email === '',
            comment: comment === '',
        };

        setErrors(newErrors);

        if (!newErrors.name && !newErrors.company && !newErrors.phone && !newErrors.email && !newErrors.comment) {
            console.log('submit this form with Data:', formData);
        }

        const url = 'https://5y3jconqy6a57eymgqcm3irjii0twzul.lambda-url.ap-southeast-1.on.aws/contact_us';
        console.log('formData', formData);

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((res: any) => {
            console.log(res);
            formData.name = '';
            formData.company = '';
            formData.phone = '';
            formData.email = '';
            formData.comment = '';
            navigate('/thankyou');
        }).catch((e: any) => {
            console.log(e);
            alert('Form submission failed. Please send us an email at aashwiin@excelfixer.com instead');
        });
    };

    return (
        <Box sx={{ boxShadow: '4px 4px 4px rgba(0,0,0,0.2)', border: '0.05px solid lightgrey', zIndex: 1, backgroundColor: 'white' }} padding={3} borderRadius={3}>
            <form onSubmit={handleSubmit}>
                {/* <h2>Login Form</h2> */}
                <TextField
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3, }}
                    fullWidth
                    value={formData.name}
                    error={errors.name}
                />
                <TextField
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={formData.email}
                    error={errors.email}
                />
                <TextField
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={formData.phone}
                    error={errors.phone}
                />
                <TextField
                    label="Company"
                    name="company"
                    onChange={handleChange}
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={formData.company}
                    error={errors.company}
                />

                <TextField
                    label="Message"
                    name="comment"
                    onChange={handleChange}
                    multiline
                    minRows={3}
                    maxRows={5}
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={formData.comment}
                    error={errors.comment}
                />

                <Button variant="contained" type="submit" sx={{ backgroundColor: 'primary.dark', color: 'white' }}>
                    Submit
                </Button>
            </form>
        </Box>
    );
}

export default ContactForm;