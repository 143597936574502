import { Box, Container, Grid, Typography, useTheme, } from "@mui/material";
import { Link } from 'react-router-dom';
import FooterTitle from "./FooterTitle";


const Footer = () => {

    const quickLinks = [
        { text: 'Home', link: '/', id: 'HomeDiv' },
        { text: 'Products', link: '/products', id: 'a3' },
        { text: 'Our Work', link: '/our-work', id: 'a2' },
        { text: 'Contact Us', link: '/contact', id: 'ContactDiv' },
    ]

    const goToSection = (div: string) => {
        console.log('working', div);
        const el = document.getElementById(div);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('element not found');
        }
    }

    return (
        <Box style={{
            backgroundImage: `url("/img/FooterBG.png")`,
            backgroundSize: "cover",
            color: 'white',
            // height: "100vh",
        }}>
            <Container max-width="lg" sx={{ p: 4, pt: 4 }}>
                <Grid container>
                    <Grid item md={4} xs={12} sx={{ pb: 3 }}>
                        <Container maxWidth="sm">
                            <Box component="img" sx={{
                                width: { xs: 100, md: 150 },
                                height: { xs: 55, md: 60 },
                                paddingBottom: 2,
                            }} src="/img/FooterLogo.png"></Box><br />

                            <iframe frameBorder="0" width="80" height="80" src="https://shareables.clutch.co/share/badges/1779305/51450?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Web Design Company Consumer Products Services Malaysia"></iframe>
                            <iframe frameBorder="0" width="80" height="80" src="https://shareables.clutch.co/share/badges/1779305/31489?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Flutter Developers Malaysia 2023"></iframe>
                            <iframe frameBorder="0" width="80" height="80" src="https://shareables.clutch.co/share/badges/1779305/45619?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Machine Learning Company Malaysia 2023"></iframe>
                            <br />
                            <a target="_blank" href="https://www.sortlist.com/agency/excelfixer-sdn-bhd" style={{ display: 'inline-block', width: '80px', height: '20px', paddingTop: 4 }}><img width="1px" height="1x" src="https://core.sortlist.com//_/apps/core/images/badges-en/badge-flag-blue-light-xl.svg" alt="flag" /></a>
                        </Container>
                    </Grid>
                    <Grid item md={4} xs={12} sx={{ pb: 3 }}>
                        <FooterTitle text="Quick Links" />
                        {quickLinks.map((obj, index) => (
                            <Link to={obj.link} className="footer-link" key={`ql-${index}`}>
                                <Typography variant="body1" marginBottom={1} color={'neutral.contrastText'}>{obj.text}</Typography>
                            </Link>
                        ))}
                    </Grid>
                    {/* <Grid item md={3} xs={12} sx={{ pb: 3 }}> */}
                    {/* <FooterTitle text="Services" /> */}
                    {/* </Grid> */}
                    <Grid item md={4} xs={12} sx={{ pb: 3 }}>
                        <FooterTitle text="Get In Touch" />
                        <Typography variant="body1" marginBottom={1} color={'neutral.contrastText'}>Unit 311, Block A, Damansara Intan, 47400 Petaling Jaya, Selangor, Malaysia.</Typography>
                        <Box sx={{ display: 'flex}' }} paddingBottom={2}>
                            <img src="/img/icons/phone.png" width={30} alt="" />
                            <Typography paddingLeft={2} variant="body1" color={'neutral.contrastText'}>+6019 622 7228</Typography>
                        </Box>
                        <Box sx={{ display: 'flex}' }}>
                            <img src="/img/icons/email.png" width={30} alt="" />
                            <Typography paddingLeft={2} variant="body1" color={'neutral.contrastText'}>info@excelfixer.com</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;