// import { Outlet, Link } from "react-router-dom";
import Button from '@mui/material/Button';

const Home = () => {
    return (
        <div>
            <Button variant="contained">Hello World</Button>
        </div>
    )
};

export default Home;