import Typography from "@mui/material/Typography"

type HiddenH1Props = {
    text: string;
};

const HiddenH1: React.FC<HiddenH1Props> = ({ text }) => {
    return (
        <Typography variant="h1" color={'rgba(52, 52, 52, 0)'} style={{ fontSize: '1px' }}>{text}</Typography>
    )
}

const HiddenH2: React.FC<HiddenH1Props> = ({ text }) => {
    return (
        <Typography variant="h2" color={'rgba(52, 52, 52, 0)'} style={{ fontSize: '1px' }}>{text}</Typography>
    )
}

export {
    HiddenH1,
    HiddenH2,
}
