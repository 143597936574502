import { Container, Typography } from "@mui/material"

export interface StepObj {
    count: number,
    img: string,
    title: string,
    desc: string,
}

const ChatStep = (x: StepObj) => {
    return (
        <Container sx={{ textAlign: 'left' }}>
            <Typography variant="body1" color="initial" sx={{ mb: 2 }}>Step {x.count}</Typography>
            <img src={x.img} width={'100px'} style={{ marginBottom: 10 }} alt="chat steps" />
            <Typography variant="body1" fontWeight={600} color="initial" sx={{ mb: 2 }}>{x.title}</Typography>
            <Typography variant="body2" fontWeight={300} color="initial">{x.desc}</Typography>
        </Container>
    );
}

export default ChatStep;