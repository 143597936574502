import Box from "@mui/material/Box";
import Seo from "../widgets/Seo";
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom";


const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Seo title="Thank You" desc="Thank you for getting in touch with Excelfixer's Web Development & Mobile App Development team"></Seo>
            <Box style={{
                backgroundColor: 'white',
                backgroundSize: "cover",
            }}>
                <Container maxWidth="lg" sx={{ textAlign: 'center', minHeight: '70vh', paddingTop: '8vh' }}>
                    <img src="/img/icons/envelope.png" height={'200px'} alt="" />
                    <Typography variant="h4" fontWeight={600} color="initial" >Thanks for getting in touch!</Typography>
                    <Typography variant="body1" color="initial" marginTop="20px">Your message has been sent. Our team will get back to you within 24 hours.</Typography>
                    <Button variant="contained" sx={{ backgroundColor: 'primary.main', color: 'white', marginTop: '20px' }} onClick={() => navigate('/our-work')}>
                        View Past Works
                    </Button>
                </Container>
            </Box >
        </div>
    );
}

export default ThankYou;